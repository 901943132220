import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {
    GetAllTripsResponseDto,
    GetAllTripsResponseDtoSchema,
    StopDetailsDto,
    TrackingStatus,
    TripDetailsDto,
    TripDetailsDtoSchema,
} from '@models/trip.model';
import { catchError, map } from 'rxjs/operators';
import { SchemaValidatorService } from '@shared/services/schema-validator.service';
import { format } from 'date-fns';
import { TripFilters } from './models/trip-filters.model';
import { MessageTemplateDto, MessageTemplateDtoSchema } from '@models/message-template.model';
import { UserStorageService } from '../login/user-storage.service';
import { RuntimeConfigService } from '@shared/services/runtime-config.service';

@Injectable({
    providedIn: 'root',
})
export class TripService {
    #http = inject(HttpClient);
    #schemaValidator = inject(SchemaValidatorService);
    #userStorageService = inject(UserStorageService);
    #runtimeConfigService = inject(RuntimeConfigService);

    getTrips$(filters: TripFilters, pageIndex: number, pageSize: number): Observable<GetAllTripsResponseDto> {
        let params = this.#createTripFilterParams(filters);
        params = params.set('page', pageIndex);
        params = params.set('itemsPerPage', pageSize);

        return this.#http.get<GetAllTripsResponseDto>(`${this.#runtimeConfigService.apiUrl()}/trips`, { params }).pipe(
            map((data) => this.#schemaValidator.validate(GetAllTripsResponseDtoSchema, data)),
            catchError((error) => this.#handleError(error)),
        );
    }

    getTripDetail$(id: string, operationDate: Date, includeLinkedTrips: boolean): Observable<TripDetailsDto> {
        let params = new HttpParams().set('operationDate', format(operationDate, 'yyyy-MM-dd'));
        if (includeLinkedTrips) {
            params = params.set('displayVehicleService', 'true');
        }

        return this.#http.get<TripDetailsDto>(`${this.#runtimeConfigService.apiUrl()}/trips/${id}`, { params }).pipe(
            map((data) => this.#schemaValidator.validate(TripDetailsDtoSchema, data)),
            catchError((response) => {
                if (response instanceof HttpErrorResponse) {
                    /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */
                    if (response.error.error === 'Trip is not active at given date') {
                        return throwError(
                            () => new Error(`La course n'est pas active à la date demandée (${format(operationDate, 'dd.MM.yyyy')})`),
                        );
                    }
                }
                return this.#handleError(response);
            }),
        );
    }

    getMessageTemplates$(): Observable<MessageTemplateDto[]> {
        const sid = this.#userStorageService.getUser()?.sid;

        return this.#http.get<MessageTemplateDto[]>(`${this.#runtimeConfigService.apiUrl()}/message-templates?sid=${sid}`).pipe(
            map((data) => this.#schemaValidator.validateMany(MessageTemplateDtoSchema, data)),
            catchError((error) => this.#handleError(error)),
        );
    }

    getTrackingStatusLabel(trackingStatus: TrackingStatus): string {
        const statusMap: Record<string, string> = {
            on_time: `À l'heure`,
            delayed: 'En retard',
            early: 'En avance',
            lost: 'Perdu',
            stationary: 'Stationnaire',
            no_signal: 'Pas de signal',
            finished: 'Terminé',
            unspecified_delay: 'Retard non spécifié',
        };
        if (trackingStatus === null || trackingStatus === undefined) {
            return 'Statut inconnu';
        }

        return statusMap[trackingStatus] || 'Statut invalide';
    }

    getVehicleStatsUrl(tripId: string, operationDate: Date): string {
        const sid = this.#userStorageService.getUser()?.sid;
        return `${this.#runtimeConfigService.config()?.backendUrl}/admin?sid=${sid}&rub=VehicleStatAdmin&roid=${tripId}&operationDate=${format(operationDate, 'yyyy-MM-dd')}`;
    }

    #handleError(error: unknown): Observable<never> {
        console.error('An error occurred:', error);
        return throwError(() => new Error('Une erreur est survenue lors de la communication avec le serveur'));
    }

    #createTripFilterParams(filters: TripFilters): HttpParams {
        let params = new HttpParams();

        if (filters.operationDate) {
            params = params.set('operationDate', format(filters.operationDate, 'yyyy-MM-dd'));
        }

        if (filters.commercialStopId) {
            params = params.set('stopAreaId', filters.commercialStopId);
        }

        if (filters.networkIds) {
            params = params.set('networkIds', filters.networkIds);
        }

        if (filters.commercialLineGroupIds) {
            params = params.set('commercialLineGroupIds', filters.commercialLineGroupIds);
        }

        if (filters.commercialLineIds) {
            params = params.set('commercialLineIds', filters.commercialLineIds);
        }

        if (filters.operationUnitId) {
            params = params.set('operationUnitId', filters.operationUnitId);
        }

        if (filters.vehicleServiceId) {
            params = params.set('vehicleServiceId', filters.vehicleServiceId);
        }

        if (filters.tripNumber) {
            params = params.set('serviceNumber', filters.tripNumber);
        }

        // Les filtres suivants ne sont pas directement supportés par l'API selon la spécification Swagger fournie.
        // Ils sont commentés mais peuvent être décommentés si l'API est mise à jour pour les supporter.

        if (filters.tripWithAvailableReservation !== undefined) {
            params = params.set('onlyWithReservationAllowed', filters.tripWithAvailableReservation.toString());
        }

        if (filters.onlyActiveAndFutureTrips !== undefined) {
            params = params.set('onlyCurrentAndFuture', filters.onlyActiveAndFutureTrips.toString());
        }

        if (filters.withRegulationActions !== undefined) {
            params = params.set('onlyWithRegulationActions', filters.withRegulationActions.toString());
        }

        // if (filters.viewByReservationPoint !== undefined) {
        //   params = params.set('viewByReservationPoint', filters.viewByReservationPoint.toString());
        // }

        if (filters.startTime !== undefined) {
            params = params.set('minDepartureTime', filters.startTime);
        }

        if (filters.endTime !== undefined) {
            params = params.set('maxDepartureTime', filters.endTime);
        }

        return params;
    }

    isActiveStop(stop: StopDetailsDto): boolean {
        const departureDate = stop.realtimeData?.departureSchedule ?? stop.theoreticalData.departureSchedule;
        const arrivalDate = stop.realtimeData?.arrivalSchedule ?? stop.theoreticalData.arrivalSchedule;
        if (departureDate) return new Date() < new Date(departureDate);
        if (arrivalDate) return new Date() < new Date(arrivalDate);
        return false;
    }
}
